class ApiConfig {

	static baseUrl = 'https://www.shicai211.com/';
	static path = '';
	static uploadUrl = '';
	static uploadPath = '';
	static cdnUrl = "https://www.shicai211.com";
	static staticImgUrl = ''; 

}

export default ApiConfig;